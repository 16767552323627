import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import Dropzone from 'react-dropzone';
import { Auth, Storage } from 'aws-amplify';
import Cookies from 'universal-cookie';

import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';

import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useLoaderDispatch } from 'context/LoaderContext';

import constants from 'constants/index';
import { getTenantIdFromLS } from 'utils/localStorageUtils';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { useSecureMessageContext } from 'modules/messages/SecureMessages/Context/SecureMessageContext';
import useExistingFiles from 'hooks/useExistingFiles';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { uuidv4 } from 'common/uuidGenerator';
import GlobalTooltip from 'components/GlobalTooltip';
const cookie = new Cookies();

function InputContainer({ messageDetails, hasPermission }) {
  const history = useHistory();

  const userContext = useContext(UserDetailsContext);
  const websocketContext = useContext(WebSocketConnectionContext);
  const setLoader = useLoaderDispatch();

  const { isLeadSpecific, caseDetails, isCaseClosed, isLeadClosed, isFromCaseSpecificModule, onMountUnMount, onOperationBreak } =
    useSecureMessageContext();
  const { existingFiles, getExistingFiles } = useExistingFiles();

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReply, setIsReply] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    resetField,
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (!isReply) {
      setFiles([]);
    }
  }, [isReply]);

  useEffect(() => {
    isReply && isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('message-reply', 'mounted');
    return () => {
      isReply && isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
    };
  }, [isReply]);

  useEffect(() => {
    if (messageDetails?.case_id) {
      getExistingFiles(`&case_id=${messageDetails?.case_id}&format=`);
    }
  }, [messageDetails.case_id]);

  const onChangeFile = (filesData) => {
    clearErrors('replyMessage');
    setFiles([...files, ...filesData]);
  };

  const reply = async (data) => {
    try {
      setIsLoading(true);
      const tenantId = getTenantIdFromLS();

      let sendData = {};

      var finalTask = [];
      let finalEvents = [];

      let attachments = await fileUpload();
      if (data.existing_files && data.existing_files.length > 0) {
        attachments = [...attachments, ...data.existing_files];
      }
      sendData.action = 'message';
      sendData.type = 'secure_message';

      sendData.data = {};
      sendData.data.secure_message_type = 'reply';
      sendData.data.tenant_id = tenantId;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo && userInfo.attributes) {
        sendData.creator_first_name = userInfo.attributes['custom:first_name'];
        sendData.creator_last_name = userInfo.attributes['custom:last_name'];
        sendData.creator_email = userInfo.attributes['email'];
      }

      sendData.data.message_thread_id = messageDetails.message_thread_id;

      sendData.data.body = {};
      sendData.data.body.message = data.replyMessage ?? '';
      sendData.data.body.attachment = attachments;
      sendData.data.body.event_attachment = finalEvents;
      sendData.data.body.task_attachment = finalTask;
      sendData.caseId = messageDetails.case_id;
      sendData.case_id = messageDetails.case_id;

      sendData.data.body.case_id = messageDetails.case_id;
      sendData.data.body.case_name = messageDetails.case_name;

      await websocketContext.sendMessage(sendData);
      isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('message-reply', 'unmounted');
      setIsLoading(false);
      setIsReply(false);
      resetField('replyMessage');
      resetField('existing_files');
    } catch (err) {
      isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
      console.log(err);
    }
  };

  async function fileUpload() {
    const tenantId = (await cookie.get('tenantId')) || '';
    const moduleName = 'messages';
    let attachments = [];
    if (files && files.length > 0) {
      setLoader(true);
      try {
        for (let i = 0; i < files.length; i++) {
          let userId;
          SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
          let user = await Auth.currentAuthenticatedUser();
          userId = user.attributes.sub;
          let caseId = isLeadSpecific ? caseDetails.case_id : history.location.pathname.split('/').reverse()[0];
          let response = await Storage.put(
            `${moduleName}/${tenantId}/${caseId}/${userId}/${uuidv4()}#$#${encodeURIComponent(files[i].name)}`,
            files[i],
            {
              contentType: files[i].type,
              progressCallback: (progress) => {
                const { loaded, total } = progress;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                }
              },
              useAccelerateEndpoint: true,
            }
          );
          if (response.key) {
            let file_type = files[i].type.toString();
            let file_details = {
              file_name: files[i].name,
              display_name: files[i].name,
              size: files[i].size.toString(),
              file_path: 'public/' + response.key,
              file_type: file_type,
              created_by: user.attributes,
            };
            attachments.push(file_details);
          }
        }
        setLoader(false);
        return attachments;
      } catch (e) {
        console.log(e);
        setLoader(false);
        return attachments;
      }
    } else {
      setLoader(false);
      return attachments;
    }
  }

  return (
    <div className="row mt-2">
      {isReply ? (
        <>
          <div className="col-sm-12 mb-3">
            <Controller
              control={control}
              name="replyMessage"
              rules={{ required: files.length > 0 ? false : true }}
              render={({ field: { onChange, value } }) => (
                <TextSnippetWrapper value={value} type="editor" style={{ height: '150px' }} onChange={(e) => onChange(e)} />
              )}
            />
            {errors?.replyMessage && <InputErrorMessage>Please enter your message</InputErrorMessage>}
          </div>

          <div className="">
            <ul className="list-unstyled">
              {files &&
                files.length > 0 &&
                files.map((val, index) => (
                  <div className="d-flex align-items-center">
                    <i className="fas fa-paperclip me-2 mt-1"></i>
                    <li key={index} className="text-break ms-1">
                      {val.name}
                    </li>
                    <i
                      className="icon-delete ms-3 pointer"
                      onClick={() => {
                        let removedFiles = files.filter((data) => data.path !== val.path);
                        setFiles(removedFiles);
                      }}
                    ></i>
                  </div>
                ))}
            </ul>
            <div className="col-12 px-2 drag-drop-box d-flex flex-wrap justify-content-center align-items-center">
              <Dropzone
                onDrop={(acceptedFiles) => onChangeFile(acceptedFiles)}
                accept="application/pdf, application/msword, .docx, .doc, audio/*, video/*,image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="d-flex align-items-center">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="text-center mb-0">Click or drag file to this area to upload</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <p className="mb-0 d-flex align-items-center">
                <i>or</i>
              </p>
              <Controller
                name="existing_files"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <MultiSelect
                    options={
                      existingFiles?.length === 0
                        ? [
                            {
                              label: 'No Records Found',
                              value: 'no-records-found',
                            },
                          ]
                        : existingFiles
                    }
                    className="input-shadow existing-files"
                    placeholder="Select Existing Files"
                    display="chip"
                    value={value}
                    filter
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                    disabled={!messageDetails.case_id}
                    optionLabel="label"
                    optionValue="value"
                    optionDisabled={(index) => index.value === 'no-records-found'}
                    showClear
                    resetFilterOnHide
                    id={name}
                    emptyFilterMessage="No records found"
                    showSelectAll={false}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-8 mt-3 offset-4 text-end">
            <Button
              label="Cancel"
              className="p-button-secondary outline py-1 px-3 me-3"
              type="button"
              onClick={() => setIsReply(!isReply)}
            />
            <Button
              label="Send"
              className="btn-outline p-button-secondary py-1 px-3 "
              type="button"
              onClick={handleSubmit(reply)}
              style={{ float: 'right' }}
              loading={isLoading}
            />
          </div>
        </>
      ) : (
        <div className="col-sm-12 text-end border-top">
          {userContext.getPermission('messages') === constants.permissions.WRITE && (
            <GlobalTooltip tooltip={hasPermission ? '' : 'You are not part of this thread'}>
              <Button
                label="Reply"
                className="btn-outline p-button-secondary py-1 px-3 mt-3"
                type="button"
                onClick={() => setIsReply(!isReply)}
                disabled={isCaseClosed || (isLeadClosed && isLeadSpecific) || !hasPermission}
              />
            </GlobalTooltip>
          )}
        </div>
      )}
    </div>
  );
}

export default InputContainer;
