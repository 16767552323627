import React from 'react';
import { Card } from 'primereact/card';

import Conversations from './Conversations';
import InputContainer from './InputContainer';
import Sidebar from 'modules/messages/SecureMessages/Sidebar/Sidebar';

function MessageContainer({ messageDetails, showSideBar, hasPermission }) {
  return (
    <div className="inner-conversation">
      <div className="row mb-3">
        <div className={showSideBar ? 'col-md-8 col-sm-12' : 'col-md-12'}>
          <Card className="shadow-middle">
            <Conversations messageDetails={messageDetails} />
            <InputContainer messageDetails={messageDetails} hasPermission={hasPermission} />
          </Card>
        </div>
        {messageDetails && showSideBar && (
          <div className="col-md-4 col-sm-12 p-0">
            <div
              className="input-shadow bg-white p-2 sidebar-height"
              style={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: 0 }}
            >
              <Sidebar messageDetails={messageDetails} hasPermission={hasPermission} className="h-100" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageContainer;
