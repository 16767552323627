import React, { useContext, useRef } from 'react';

import { classNames } from 'primereact/utils';

import { UserDetailsContext } from 'context/userDetailsContext';
import { Tooltip } from 'primereact/tooltip';
import { GroupUserTooltip } from 'components/UI/GroupUserTooltip/GroupUserTooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';

function ConversationBetween({ messageDetails, redirectToPage, fromSidebar }) {
  const userContext = useContext(UserDetailsContext);
  const op = useRef(null);

  const remainingFirmUsers = messageDetails?.message_to
    ?.filter((data) => data?.recipient_id !== userContext.firmDetails.current_user && data?.firm_user_id)
    .slice(2);

  const handleMoreClick = (e) => {
    op.current?.toggle(e); // Toggle the overlay panel when "more" is clicked
  };

  const handleModuleRedirectionForCurrentUser = (e) => {
    let currentUser = messageDetails?.message_to?.filter((data) => data?.firm_user_id === userContext?.firmDetails?.firm_user_id);
    let route = '/';

    if (currentUser?.length && currentUser[0]?.firm_user_id && currentUser[0]?.access_level !== 'client') {
      route = `/settings/team-management/${currentUser[0]?.firm_user_id}`;
    }
    redirectToPage(e, route);
  };

  const handleModuleRedirection = (e, data, type) => {
    let route = '/';

    if (type === 'group') {
      route = '/settings/groups';
    } else {
      if (data?.access_level === 'client') {
        route = data?.contact_id ? `/contacts/${data?.contact_id}` : '/contacts';
      } else if (data?.access_level !== 'client') {
        route = data?.firm_user_id ? `/settings/team-management/${data?.firm_user_id}` : '/settings/team-management';
      }
    }

    redirectToPage(e, route);
  };

  return !fromSidebar ? (
    <div className="row mb-4">
      <div className="col-sm-12">
        <span className="F-size14">Conversation Between: </span>
        {messageDetails?.message_to?.filter((data) => data?.recipient_id === userContext.firmDetails.current_user)?.length > 0 && (
          <span
            style={{ marginLeft: '0.5rem' }}
            className=" F-size14 text-primary-dark text-decoration-none case-details-link pointer"
            onClick={(e) => handleModuleRedirectionForCurrentUser(e)}
          >
            You{', '}
          </span>
        )}
        {messageDetails?.groups?.map((msg, index) => (
          <span
            key={index}
            className={classNames({
              'F-size14 text-primary-dark text-decoration-none case-details-link pointer': true,
              'disabled-element': msg?.group_status === 'Deleted',
              ['name-' + index]: true,
            })}
            onClick={(e) => msg?.group_status !== 'Deleted' && handleModuleRedirection(e, msg, 'group')}
          >
            {`${msg?.group_name} (Group${
              msg?.group_status === 'ARCHIVED' ? ' - Archived' : msg?.group_status === 'Deleted' ? ' - Deleted' : ''
            })`}
            {index < messageDetails?.groups?.length - 1 ? ', ' : ''}
            <Tooltip target={`.name-${index}`} position="top">
              {GroupUserTooltip(msg, messageDetails?.message_to)}
            </Tooltip>
          </span>
        ))}
        {messageDetails?.message_to?.filter(
          (data) => data?.recipient_id !== userContext?.firmDetails?.current_user && data?.is_individual_user
        )?.length > 0 &&
          messageDetails?.groups?.length > 0 &&
          ', '}

        {messageDetails?.message_to
          ?.filter((data) => data?.recipient_id !== userContext.firmDetails.current_user && data.is_individual_user)
          .map((msg, index) => (
            <span
              key={index}
              className=" F-size14 text-primary-dark text-decoration-none case-details-link pointer class-tooltip"
              onClick={(e) => handleModuleRedirection(e, msg, 'user')}
            >
              <>
                {msg?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                {msg?.recipient_name} ({msg?.access_level ? msg?.access_level : 'Client'})
                {index <
                messageDetails?.message_to?.filter(
                  (data) => data?.recipient_id !== userContext?.firmDetails?.current_user && data?.is_individual_user
                )?.length -
                  1
                  ? ', '
                  : ''}
              </>

              {msg.status === 'ARCHIVED' && (
                <Tooltip
                  position="top"
                  target=".class-tooltip"
                  content={msg?.status?.toLowerCase()?.charAt(0)?.toUpperCase() + msg?.status?.slice(1)?.toLowerCase()}
                />
              )}
            </span>
          ))}
      </div>
    </div>
  ) : (
    <div className="row mb-4">
      <div className="col-sm-12">
        <span className="F-size14">Conversation Between: </span>

        {messageDetails?.message_to?.filter((data) => data?.recipient_id === userContext?.firmDetails?.current_user)?.length > 0 && (
          <span
            style={{ marginLeft: '0.5rem' }}
            className="F-size14 text-primary-dark text-decoration-none case-details-link pointer"
            onClick={(e) => handleModuleRedirectionForCurrentUser(e)}
          >
            You{', '}
          </span>
        )}

        {messageDetails?.message_to
          ?.filter((data) => data?.recipient_id !== userContext.firmDetails?.current_user && data?.firm_user_id)
          .slice(0, 2) // Display only the first 3 recipients
          .map((msg, index) => (
            <span
              key={index}
              className="F-size14 text-primary-dark text-decoration-none case-details-link pointer class-tooltip"
              onClick={(e) => handleModuleRedirection(e, msg, 'user')}
            >
              <i className="fas fa-user-tie me-2"></i>
              {msg?.recipient_name} ({msg?.access_level && msg?.access_level})
              {index <
              messageDetails?.message_to?.filter(
                (data) => data?.recipient_id !== userContext?.firmDetails?.current_user && data?.firm_user_id
              )?.length -
                1
                ? ', '
                : ''}
              {msg.status === 'ARCHIVED' && (
                <Tooltip
                  position="top"
                  target=".class-tooltip"
                  content={msg?.status?.toLowerCase()?.charAt(0)?.toUpperCase() + msg?.status?.slice(1)?.toLowerCase()}
                />
              )}
            </span>
          ))}

        {messageDetails?.message_to?.filter((data) => data?.recipient_id !== userContext.firmDetails.current_user && data?.firm_user_id)
          .length > 2 && (
          <span className="F-size14 text-primary-dark">
            {remainingFirmUsers?.length > 0 && (
              <Button
                className={`p-mr-2 pa-tags more-tag-button firm-users-more-button`}
                label={`+${remainingFirmUsers.length} members`}
                onClick={handleMoreClick}
              />
            )}
          </span>
        )}

        {/* OverlayPanel for remaining members */}
        <OverlayPanel ref={op} className="attorney-overlay">
          <div className="attorney-card-content case-report-group">
            {remainingFirmUsers?.map((msg, index) => (
              <div key={index} className="p-mb-2">
                <span
                  className="F-size14 text-primary-dark text-decoration-none case-details-link pointer class-tooltip"
                  onClick={(e) => handleModuleRedirection(e, msg, 'user')}
                >
                  <i className="fas fa-user-tie me-2"></i>
                  {msg?.recipient_name} ({msg?.access_level && msg?.access_level})
                  {msg.status === 'ARCHIVED' && (
                    <Tooltip
                      position="top"
                      target=".class-tooltip"
                      content={msg?.status?.toLowerCase()?.charAt(0)?.toUpperCase() + msg?.status?.slice(1)?.toLowerCase()}
                    />
                  )}
                </span>
              </div>
            ))}
          </div>
        </OverlayPanel>
      </div>
    </div>
  );
}

export default ConversationBetween;
