import React, { useContext, useEffect } from 'react';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import { useForm, Controller } from 'react-hook-form';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';

import LabelName from 'components/UI/LabelName/LabelName';
import { updateCalendarSettings } from 'services/Calender/calendarServices';

import { OutLookContext } from 'context/OutlookContext';
import useGoogleCalendars from 'hooks/useGoogleCalendars';
import { SpinnerComponent } from 'shared/SpinnerComponent';

function CalendarSettings() {
  const { control, handleSubmit, setValue, watch } = useForm();

  const { showCalendarSettings, setShowCalendarSettings, calendarSettings, setCalendarSettings } = useCalendarContext();
  const { disconnectGoogleCal, generateRedirectUrl, getGoogleCalendarList, calendarList, showLoader } = useGoogleCalendars();

  const { user, signIn, signOut } = useContext(OutLookContext);

  let syncOutlookWatcher = watch('sync_with_outlook');
  let sync_with_google_calendar = watch('sync_with_google_calendar');
  let calendar_id = watch('calendar_id');

  useEffect(() => {
    if (calendarSettings) {
      setValue('show_weekends', calendarSettings?.show_weekends);
      setValue('show_sol', calendarSettings?.show_sol);
      setValue('show_birthdays', calendarSettings?.show_birthdays);
      setValue('show_business_times_only', calendarSettings?.show_business_times_only);
      setValue('sync_with_google_calendar', calendarSettings?.sync_with_google_calendar);
      setValue('sync_with_outlook', calendarSettings?.sync_with_outlook && user ? true : false);
      setValue('calendar_id', calendarSettings?.calendar_id);
      setValue('primary', calendarSettings?.primary);
    }
  }, [calendarSettings]);

  useEffect(() => {
    if (!user && syncOutlookWatcher) {
      signIn();
    }
  }, [syncOutlookWatcher]);

  function hideSideBar() {
    setShowCalendarSettings(false);
  }

  async function handleSettingsSubmit(data) {
    if (data?.sync_with_google_calendar === false) {
      await disconnectGoogleCal();
    }
    updateCalendarSettings(data)
      .then((res) => {
        setCalendarSettings(data);
        // outlook
        if (!data.sync_with_outlook && user) {
          signOut();
        }
        hideSideBar();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Sidebar
      visible={showCalendarSettings}
      position="right"
      onHide={hideSideBar}
      showCloseIcon={false}
      className="p-sidebar-md filter-slidebar eventsetting-slider"
    >
      {showLoader && <SpinnerComponent />}
      <div className="row">
        <div className="col-12 d-flex justify-content-between salign-items-center py-2 mb-4">
          <div className="d-flex align-items-center">
            <div>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  hideSideBar();
                }}
              >
                <div className="icon-box me-2">
                  <i className="pi pi-angle-left"></i>
                </div>
              </a>
            </div>
            <h5 className="sub-title mb-0"> Calendar Settings</h5>
          </div>
          <Button type="submit" className="p-button p-button-secondary" onClick={handleSubmit(handleSettingsSubmit)} label="Save Changes" />
        </div>
      </div>
      <div className="row px-5">
        <div className="col-12 px-0">
          <h5 className="caption-bold">preview option</h5>
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show Weekends</LabelName>
          <Controller
            name="show_weekends"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show Business Time Only</LabelName>
          <Controller
            name="show_business_times_only"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show Birthdays</LabelName>
          <Controller
            name="show_birthdays"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between">
          <LabelName>Show SOL</LabelName>
          <Controller
            name="show_sol"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
      <div className="row mt-4 px-5">
        <div className="col-12 px-0">
          <h5 className="caption-bold"> Synchronization</h5>
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between  mb-3">
          <LabelName>Sync with Outlook</LabelName>
          <Controller
            name="sync_with_outlook"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Sync with Google Calendar</LabelName>
          <Controller
            name="sync_with_google_calendar"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  if (e.value) {
                    generateRedirectUrl((res) => {
                      setValue('sync_with_google_calendar', res);
                      getGoogleCalendarList();
                    });
                  } else {
                    field.onChange(e.value);
                  }
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
      {!!sync_with_google_calendar && calendarList?.length > 0 && (
        <div className="row ps-5 pe-3">
          <div className="col-12 px-0 align-items-center justify-content-between mb-3">
            <LabelName>Select a google calendar to sync</LabelName>
            <Controller
              name="calendar_id"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  options={calendarList || []}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                    setValue('calendar_id', e?.value);
                    setValue('primary', calendarList?.find((calendar) => calendar.id === e?.value)?.primary);
                  }}
                  className="w-100"
                />
              )}
            />
          </div>
          {!calendar_id && (
            <span className="d-flex p-0 align-items-center">
              <i className="pi pi-info-circle me-1"></i>By default, primary google calendar will be displayed.
            </span>
          )}
        </div>
      )}
    </Sidebar>
  );
}

export default CalendarSettings;
